function initAll() {
  const hairPic = document.querySelector('.bg-pic');
  if(hairPic) {
    const changeBg = setInterval(() => {
      if(hairPic.classList.contains('sec-pic')) {
        hairPic.classList.remove('sec-pic');
      } else {
        hairPic.classList.add('sec-pic');
      }
    }, 7000);
  }

  const sizes = Array.prototype.slice.call(document.querySelectorAll('b.size'));
  const form = document.querySelector('aside.contacts-box form');
  const txtField = form.querySelector('input[name=name]');

  if(sizes) {
    function removeCurrentSize () {
      sizes.forEach((elem) => {
        elem.classList.remove('current-size');
      })
    }

    sizes.forEach((elem) => {
      elem.addEventListener('click', () => {
        removeCurrentSize();
        elem.classList.add('current-size');
        const size = elem.dataset.size;
        document.getElementById('size-holder').value = size;
        document.querySelector('.sizes-header').innerHTML = `Вы выбрали <b>${size}</b>`;
        form.classList.add('focused');
        txtField.focus();
      })
    })

  }
}

window.addEventListener('DOMContentLoaded', initAll);

function fixingNav() {
  const nav = document.querySelector('.gallery-headers');
  const navFixedTrigger = document.querySelector('.box-fixed-trigger');
  if (nav) {
    const galFromTop = navFixedTrigger.getBoundingClientRect().top;
    if (galFromTop <= 0) {
      nav.classList.add('fixed-nav');
      navFixedTrigger.classList.add('extra');
    } else {
      nav.classList.remove('fixed-nav');
      navFixedTrigger.classList.remove('extra');
    }
  }
}

jQuery(($) => {
  const navToggle = document.querySelector('.m-top-nav-toggle');
  const topNav = document.querySelector('.top-nav');
  const logo = document.querySelector('.logo-box');

  if (navToggle) {
    navToggle.addEventListener('click', function () {
      if (this.classList.contains('m-top-nav-close')) {
        this.classList.remove('m-top-nav-close');
        logo.classList.remove('logo-box-visible');
        topNav.classList.remove('m-top-nav-visible');
      } else {
        topNav.classList.add('m-top-nav-visible');
        logo.classList.add('logo-box-visible');
        this.classList.add('m-top-nav-close');
      }
    });
  }

  window.addEventListener('resize', () => {
    if (window.innerWidth >= 958) {
      topNav.classList.remove('m-top-nav-visible');
      logo.classList.remove('logo-box-visible');
      navToggle.classList.remove('m-top-nav-close');
    }
  });

  const slidesWrap = document.querySelector('.slides-wrap');
  if (slidesWrap) {
    $(slidesWrap).owlCarousel({
      items: 1,
      autoPlay: 5000,
      stopOnHover: true,
      mouseDrag: false,
      singleItem: true,
    });
  }

  lightbox.option({
    resizeDuration: 200,
    wrapAround: true,
    showImageNumberLabel: false,
  });


  $('input[type=tel]').inputmask('mask', { mask: '+7 (999) 999-9999' });

  var galleryWraps = document.querySelectorAll('.single-gallery__container');

  if (galleryWraps) {
    var _loop = function _loop(i) {
      $(galleryWraps[i]).bxSlider({
        mode: 'vertical',
        minSlides: 2,
        moveSlides: 1,
        slideMargin: 22,
        prevText: '',
        nextText: '',
        pager: false,
        onSliderLoad: function onSliderLoad() {
          $(galleryWraps[i]).css('visibility', 'visible');
          $('.bx-clone').attr('data-lightbox', ''); // workaround for removing sliding cloned pics in lightbox;
        }
      });
    };

    for (var i = 0; i < galleryWraps.length; i++) {
      _loop(i);
    }
  }

  const works = document.querySelector('.works');
  if (works) {
    $(works).owlCarousel({
      items: 5,
      itemsTablet: [768, 2],
      itemsDesktopSmall: [940, 3],
      itemsMobile: [481, 1],
      lazyLoad: true,
      autoPlay: 5000,
      stopOnHover: true,
      mouseDrag: false,
      pagination: false,
      navigation: true,
      navigationText: false,
    });
  }

  if (YMaps && $('.contacts-branch').length) {
    YMaps.jQuery(() => {
      function callMap(mapId, firstCord, secCord) {
        if (!YMaps.jQuery(mapId).length) {
          throw new Error(`карта + ${mapId} на странице не найдена`);
        }
        const branchMap = new YMaps.Map(YMaps.jQuery(mapId));
        branchMap.setCenter(new YMaps.GeoPoint(firstCord, secCord), 17);
        branchMap.addControl(new YMaps.ToolBar());
        branchMap.addControl(new YMaps.Zoom());
        branchMap.addControl(new YMaps.ScaleLine());
        const s = new YMaps.Style();
        s.iconStyle = new YMaps.IconStyle();
        s.iconStyle.href = '/wp-content/themes/stlokonbeeplane/src/i/map-marker.png';
        s.iconStyle.size = new YMaps.Point(171, 60);
        s.iconStyle.offset = new YMaps.Point(-29, -59);
        const placemark = new YMaps.Placemark(branchMap.getCenter(), {
          style: s,
          hasBalloon: false,
        });
        branchMap.addOverlay(placemark);
      }

      // callMap('#YMapsID00', 37.62930986, 55.73757981);
      callMap('#YMapsID01', 37.616522, 55.737173);
    });
  }

});

window.addEventListener('scroll', fixingNav);

